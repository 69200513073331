import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='bg-white h-full mt-8 mb-24 flex-col justify-center items-center'>
      <h1 className='text-black mb-0 pb-0 leading-none text-[14em] lg:text-[15rem] xl:text-[20rem] text-center'>
        404
      </h1>
      <p className='text-black mt-0 pt-0 line-0 leading-loose text-[2rem] lg:text-[2rem] xl:text-[2rem] text-center'>
      죄송합니다, 페이지를 찾을 수 없습니다  ╯°□°）╯
      </p>
      <div className="homebutton mt-8 flex justify-center items-center pb-8">
        <button className="text-white bg-black border-white solid border-2 p-2 pl-4 pr-4 text-2xl hover:bg-gray-100 hover:text-gray-900">
          <Link to="/">Homepage</Link>
        </button>
      </div>
    </div>
  );
};

export default NotFound;
