import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white text-black py-6 px-6 flex flex-col items-center">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between w-full">
        <div className="flex justify-center md:justify-start mb-2 md:mb-0 md:mr-4">
          
        </div>
        <div className="text-[1.2em] lg:text-[1.350em] xl:text-[1.350em] mb-2 md:mb-0 text-center w-full">Copyright © 2024 DahnWorld, All Rights Reserved<br></br>
        <a href="/PrivacyPolicy" className="text-black mr-2 lg:mr-8 xl:mr-8 hover:underline"><span className='text-base'>Privacy Policy</span></a>
        <span className="text-gray-200">|</span>
        <a href="/TermsOfService" className="text-black ml-2 lg:ml-8 xl:ml-8 hover:underline"><span className='text-base'>Terms of Service</span></a>
      </div>
        
        
       
      </div>
     
    </footer>
  );
};

export default Footer;
