import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Main from './components/Main';


import Index from './components/Index';
import NotFound from './components/NotFound';
import Benefits from './components/Benefits';
import Founder from './components/Founder';
import Ceo from './components/Ceo';
import Hero from './components/Hero';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== '/' && <Navbar />}
      <Routes>
        <Route path="/" element={<Main />} />

        <Route path="/Index" element={<Index />} />

        <Route path="/Benefits" element={<Benefits />} />
        <Route path="/Founder" element={<Founder />} />
        <Route path="/Ceo" element={<Ceo />} />
        <Route path="/Hero" element={<Hero />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      {location.pathname !== '/' && <Footer />}
    </div>
  );
}

export default App;
