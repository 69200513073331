import React from 'react';

const Founder = () => {
  return (
    <div className='flex bg-white justify-center'>
      <div className="flex lg:flex lg:p-40 xl:p-40 xl:flex flex-col h-screen md:flex-row md:items-center justify-center">
        <div className="flex lg:flex xl:flex flex-col items-center md:items-center md:text-center">
          <p className="text-black max-w-[60em] px-8 mt-16 mb-8 lg:mb-0 xl:mb-0 text-[0.8em] lg:text-sm xl:text-sm text-center  md:text-left">
            <h2 className='text-black font-bold text-4xl lg:text-5xl xl:text-5xl mb-8 mt-8'>FOUNDER 설립자</h2>
            <img
              src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714513575/zlzghix5eyst5xdvmrq4.pnghttps://res.cloudinary.com/dzxrdh920/image/upload/v1714513575/zlzghix5eyst5xdvmrq4.png"
              alt="CEO"
              className="block md:hidden lg:hidden xl:hidden mx-auto mb-4 md:mb-0 w-[11em] h-[15em] lg:w-[25em] lg:h-[22em] xl:w-[25em] xl:h-[22em]"
            />
           단월드 설립자인 이승헌 글로벌사이버대 총장은 한국의 홍익철학과 뇌교육으로 인류의 건강과 행복과 평화를 실현하는 데 기여하고 있는 세계적인 멘탈헬스 권위자이다.
이승헌 총장은 1980년, 무료 공원지도를 시작으로 지난 30년간 홍익정신과 뇌교육을 세계에 알린 자랑스러운 한국인으로 국민훈장을 받은 것은 물론 미국의 수도 워싱턴 D.C., 뉴욕시, 샌프란시스코시를 비롯한 미국 15개 도시가 미국 시민의 건강과 행복 그리고 청소년 및 교육문제 해결에 기여한 공로를 인정하여 ‘일지 이승헌의 날(일지리데이, Ilchi Lee Day)를 지정, 선포하기도 했다.
또 워싱턴 DC, 뉴욕시, LA시를 비롯한 미국 12개 도시에서 '뇌교육의 날'을 선포하기도 했다.
이승헌 총장은 1990년 교육과학기술부의 인가를 받아 (재)한국뇌과학연구원을 설립하며 뇌교육의 바탕이 되는 명상과 호흡에 대한 체계적인 연구를 시작했고, 수많은 뇌과학자와 정신의학자들이 연구하고 싶어하는 수많은 멘탈헬스 뇌교육 컨텐츠를 개발하면서, 지난 30년 간 국민건강을 위해 콘텐츠 무료 보급 등 국민건강캠페인을 리드했다.
이승헌 총장은 단월드를 비롯해 BR뇌교육, 유답 등 아동, 기업 대상 뇌교육 전문기업들의 경영권과 소유권을 20여 년 전 제자들에게 물려준 후, 해외 개척과 교육 사업, 국학과 지구인운동에 힘써왔다.
또 최근에는 단월드가 사회적 기업으로 국민행복시대에 발맞추어 진정한 국민 복지를 위해서 변신할 수 있도록 제자들의 창업을 지원하고 컨설팅하고 있다.
단월드는 이러한 설립자의 뜻을 이어받아 국민의 건강, 행복, 평화 증진에 기여한다는 목적으로 센터를 운영하고 있으며, 단월드에서 수련한 회원들은 강사와 사범으로 성장하여 전국의 관공서, 복지기관, 기업체, 공원, 지역 커뮤니티에서 주민의 건강 복리를 위해 무료 수련 지도와 공익활동을 벌이고 있으며, 단학과 뇌교육 세계 보급을 위해서 국제 지도자를 파견하고 있다.
그가 걸어온 홍익정신의 30년 인생은 그의 저서에 담겨 있다. 한국인 최초로 미국 아마존닷컴 1위를 기록한 '힐링소사이어티'를 비롯해서 '뇌파진동', '한국인에게 고함', '숨쉬는 평화학'과 뉴욕타임스 및 미국 4대 일간지 3주 연속 베스트셀러를 기록한 ‘세도나스토리’ 등 40여 권의 책을 저술하였고, 매일(평일) 약 1백만 명에게 '일지희망편지'를 이메일로 보내고 있다. 이승헌 총장 홈페이지.</p>
        </div>
        <img
          src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714513575/zlzghix5eyst5xdvmrq4.png"
          alt="CEO"
          className="hidden md:block lg:block xl:block mx-auto mb-4 md:mb-0 w-[11em] h-[15em] lg:w-[20em] lg:h-[22em] xl:w-[20em] xl:h-[22em]"
        />
      </div>
    </div>
  );
};

export default Founder;
