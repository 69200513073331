import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="bg-white p-4  flex flex-col md:flex-row md:justify-between items-center pr-16">
      <Link to="/">
        <img
          className="h-24 mb-4 pl-16  md:mb-0 invert"
          src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714442621/qynx3lj5qyv7riu8olb5.png"
          alt="Dahnlogo"
        />
      </Link>

      <div className="md:hidden pl-16 flex flex-col items-center ">
        <div className="flex gap-8 items-center mt-2 ">
          <Link to="/NotFound" className="text-black text-sm font-bold mt-4 hover:underline">Meditation</Link>
          <Link to="/NotFound" className="text-black text-sm font-bold mt-4 hover:underline">Books</Link>
          <Link to="/NotFound" className="text-black text-sm font-bold mt-4 hover:underline">techniques</Link>
          <Link to="/NotFound" className="text-black text-sm font-bold mt-4 hover:underline">About</Link>
        </div>
      </div>
      <ul className="hidden md:flex space-x-2 lg:space-x-16 xl:space-x-16">
        <li>
          <Link to="/NotFound" className="text-black  hover:underline">Meditation </Link>
        </li>
        <li>
          <Link to="/NotFound" className="text-black  hover:underline">Books</Link>
        </li>
        <li>
          <Link to="/NotFound" className="text-black  hover:underline">Techniques</Link>
        </li>
        <li>
          <Link to="/NotFound" className="text-black  hover:underline">About</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
