import React from 'react';

const Ceo = () => {
  return (
    <div className='flex bg-white justify-center'>
      <div className="flex lg:flex lg:p-40 xl:p-40 xl:flex flex-col h-screen md:flex-row md:items-center justify-center">
        <div className="flex lg:flex xl:flex flex-col items-center md:items-center md:text-center">
          <p className="text-black max-w-[60em] px-8 mt-16 mb-8 lg:mb-0 xl:mb-0 text-[0.8em] lg:text-sm xl:text-sm text-center  md:text-left">
            <h2 className='text-black font-bold text-4xl lg:text-5xl xl:text-5xl mb-8 mt-8'>CEO 인사말</h2>
            <img
              src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714513954/mmix5im9gy8yto5omcro.png"
              alt="CEO"
              className="block md:hidden lg:hidden xl:hidden mx-auto mb-4 md:mb-0 w-[11em] h-[15em] lg:w-[25em] lg:h-[22em] xl:w-[25em] xl:h-[22em]"
            />
            단월드는 창립 이래 모두가 건강하고 행복한 사회를 만들기 위해<br/>
            기업의 역할이 무엇인지 고민해왔고, 한민족의 홍익정신이 담긴 명상수련법을 현대화시키고 대중화시켜 개인 삶의 질을 높이는 것은 물론 사회를 긍정적으로 변화시키는 사회구성원을 만드는 데 노력해왔습니다.<br/>
            단월드는 이윤 추구가 아닌 공익을 추구해온 정신문화 기업의 모델이 되기 위해 홍익의 핵심가치를 실천해 왔고, 수익금의 대부분을 공익활동을 후원하는 데 사용해 왔습니다.<br/>
            이러한 핵심가치는 그동안 30년 간 단월드가 세계적인 명상기업으로 성장할 수 있는 원동력이 되어왔습니다.<br/>
            또한 단월드 대표상품인 브레인명상의 핵심은 코리안 스피릿의 홍익정신입니다.<br/>
            명상을 하게 되면 뇌파안정과 심신안정으로 몸의 자연치유력이 극대화되고, 습관과 생각이 긍정적으로 변하게 되고, 자신과의 깊은 교류를 통해 삶의 근원적인 해답을 얻게 되어, 홍익이 깨어나게 됩니다.<br/>
            그래서 명상으로 삶의 해답을 얻는 사람들은 자기 자신은 물론 주변을 치유하고 조화롭게 변화시킵니다.<br/>
            앞으로 인간성 상실에서 오는 심각한 사회 문제와 인류문제를 해결할 홍익정신을 정신문화상품으로 더욱 대중화하고 세계화시켜 코리안 스피릿으로 지구를 경영하는 초일류 그룹을 만들어 가겠습니다.<br/>
            또한 지금까지 쌓아온 홍익경영철학을 계승하여 기업의 비전과 직원, 고객이 만족할 수 있는 삼원만족경영으로 사회에 의미 있는 성과를 만들어 나가겠습니다.<br/>
            단월드의 코리안 스피릿의 세계화라는 끝없는 도전에 고객 여러분들의 많은 성원과 격려를 부탁드립니다.<br/>
            감사합니다.
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714513954/mmix5im9gy8yto5omcro.png"
          alt="CEO"
          className="hidden md:block lg:block xl:block mx-auto mb-4 md:mb-0 w-[11em] h-[15em] lg:w-[20em] lg:h-[22em] xl:w-[20em] xl:h-[22em]"
        />
      </div>
    </div>
  );
};

export default Ceo;
