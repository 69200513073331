import React from 'react';

const Main = () => {
  const mainImageUrl = 'https://res.cloudinary.com/dzxrdh920/image/upload/v1714517279/fd6hpzrqszth8t1web8l.png';

  return (
    <div className="main bg-white overflow-hidden main w-full h-screen text-center mb-0 flex justify-center items-center">
      <div className="inline-block">
        <div className="aspect-w-26 aspect-h-8 w-screen p-4 mt-16 sm:w-full md:w-full lg:w-[calc(130vw/2)] xl:w-[calc(130vw/2)] relative flex flex-col items-center">
          <div className="aspect-w-16 aspect-h-9 w-full frame mb-4">
            <img
              className="w-full h-full"
              src={mainImageUrl}
              alt="Image"
              style={{ minHeight: '22vh' }}
            />
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Main;
