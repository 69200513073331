import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Ceo from './Ceo';

import Index from './Index';
import NotFound from './NotFound';
import Founder from './Founder';
import Navbar from './Navbar';
import Footer from './Footer';
import Hero from './Hero';

import Benefits from './Benefits';


const Main = () => {
  const whiteBarStyle = {
    width: '90%',
    height: '1px',
    backgroundColor: 'white',
    margin: '0 auto', 
    marginBottom: '40px', 
  };

  return (
    <div className="main-container w-full h-screen text-center mb-0 justify-center items-center flex-col overflow-y-auto">
      <Navbar />
      <Hero />
    
      <Benefits />
      

      <Founder />
      
      <Ceo />
      <Footer />

    
    </div>
  );
};

export default Main;
