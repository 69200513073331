import React from 'react';

const Benefits = () => {
  return (
    <div className='flex lg:block xl:block bg-white'>
      <div className="block lg:flex  lg:p-40 xl:p-40 xl:flex flex-col h-screen md:flex-row md:items-center justify-center">
        <div className="block lg:flex xl:flex flex-col items-start md:items-center md:text-center">
          <p className="text-black max-w-[60em] px-8 mt-16 mb-8 lg:mb-0 xl:mb-0 text-[0.8em] lg:text-[1em] xl:text-[1em] text-center md:text-left">
            <span>모
든 

끝
은

새
로
운 

시
작
이
다</span>
            <h2 className='text-black font-bold text-4xl lg:text-5xl xl:text-5xl mb-8 mt-8'>BENEFITS 건강 혜택</h2>
            <img
          src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714515247/as9lrxpddwcqarl784lo.png"
          alt="Tree"
          className="block md:hidden lg:hidden xl:hidden mx-auto mb-4 md:mb-0 w-[18em] h-[15em] lg:w-[25em] lg:h-[22em] xl:w-[25em] xl:h-[22em]"
          />
            The beginning of HSP® singularity dates back to the heightened era. The sensation, breakfast, and goldsmith training to become a red-eye man are the old look of the singular. The ancient singularity of humans broke the flag (子, energy), Develop a mind, discover the soul, grow the soul, It comes from human-complexing goodness (小道). The modern HSP® training meets ancient goodness and modern brain science, As the master of the brain in the 21st century information society, restoring humanity, It provides a way to live as the master of information. My body is mine, not me My feelings are mine, not me My information is mine, not me HSP® As the trilateral of Heterogeneic says in Hakji, The HSP® unit science is that humans become masters of body, emotions, and information, Help you live healthy, happy and peaceful. You can't be happy without being healthy. You can't be peaceful without being healthy and happy. For true peace First, humans must be healthy, happy and peaceful. This is the ideal human image of our people, Hong Kong Human', The ideal human image of the 21st century is a harmonious earth that transcends nationality and race. This is the vision of HSP® architecture, and is the goal pursued by all training programs The World has health, happiness and peace, HSP®. And there is a real realization for real peace.
          </p>
        </div>
        <img
          src="https://res.cloudinary.com/dzxrdh920/image/upload/v1714515247/as9lrxpddwcqarl784lo.png"
          alt="Tree"
          className="hidden md:block lg:block xl:block mx-auto mb-4 md:mb-0 w-[18em] h-[15em] lg:w-[25em] lg:h-[22em] xl:w-[25em] xl:h-[22em]"
          />
      </div>
    </div>
  );
};

export default Benefits;
